import React, { useState, useReducer, createContext, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import { useCookies } from 'react-cookie';
import ReactGA from 'react-ga';
import classnames from 'classnames';

import './i18n';
import './App.css';
import conversation from './data/chat.json';

import Footer from './components/layout/Footer';
import Game from './components/Game';
import reducer, { setFullscreen, SET_CAN_PLAY } from './context/hta-context';
import FullScreen from 'react-full-screen';
import { Videos, MIN_LOADING_TIME, APP_KEY } from './Constants';
import Loading from './components/Loading';
import axios from 'axios';
import MainMenu from './components/MainMenu';
import PopupOverlay from './components/PopupOverlay';
import Header from './components/layout/Header';

// Google Analytics
ReactGA.initialize('UA-163784800-1');
ReactGA.set({ page: window.location.pathname }); // Update the user's current page
ReactGA.pageview(window.location.pathname); // Record a pageview for the given page

export const HTAContext = createContext();

const initialState = {
  canPlay: true,
  fullscreen: false,
  captions: true,
  mute: false,
  popup: null,
  knowsRules: false,
  choices: conversation.choices
};

const App = () => {
  const [progress, setProgress] = useState(0);
  const [ready, setReady] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [cookies, setCookie] = useCookies(['authorized']);

  const { canPlay, popup, fullscreen } = state;
  
  const onFirstUserInteraction = useCallback(() => {
    document.body.removeEventListener('click', onFirstUserInteraction, false);
    dispatch({ type: SET_CAN_PLAY });
  }, [dispatch])

  // Waiting for the first user click to set the application as ready (video can't autoplay without a user interaction first)
  useEffect(() => {
    document.body.addEventListener('click', onFirstUserInteraction, false);
    return () => {
      document.body.removeEventListener('click', onFirstUserInteraction, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // First loading
  useEffect(() => {
    if (!ready) {
      let loadedFiles = 0;
      let loadingTimerDone = false;
      const videosNames = Object.keys(state.choices).filter(choiceID => !!state.choices[choiceID].video).map(choiceID => state.choices[choiceID].video)
      let nbFiles = videosNames.length;
      const loadingInfo = { loaded: 0, total: 0, subs: [] }

      const onDownloadProgress = (e) => {
        const { responseURL } = e.target;
        const isSubtitle = e.target.responseType === 'text';
        const array = isSubtitle ? loadingInfo.subs : loadingInfo.videos;
        let elementLoadingData = array.find(v => v.url === responseURL);
        if (!elementLoadingData) {
          elementLoadingData = { url: responseURL, loaded: e.loaded };
          array.push(elementLoadingData);
          loadingInfo.total += e.total;
        }
        elementLoadingData.loaded = e.loaded;
        loadingInfo.loaded = array.reduce((total, element) => total + element.loaded, 0);
        
        if (loadingInfo.subs.length >= nbFiles) {
          setProgress(loadingInfo.loaded / loadingInfo.total);
        }
      }
  
      const onFileLoaded = (res, elementName, type, err) => {
        if (err) {
          console.error(`Error loading file ${elementName}:${err}`);
          Videos[elementName] = null;
        } else {
          Videos[elementName] = { ...Videos[elementName], vtt: res.data, vttURL: res.config.url };
        }
        ++loadedFiles;
        if (loadedFiles >= nbFiles) {
          setReady(loadingTimerDone);
        }
      }
  
      for (let i = 0; i < videosNames.length; i++) {
        let videoName = videosNames[i];
        if (videoName.includes('/')) {
          ++nbFiles;
          videoName = videoName.split('/');
          axios.get(`${process.env.PUBLIC_URL}/videos/${videoName[0]}.vtt`, { responseType: 'text', onDownloadProgress })
            .then(res => onFileLoaded(res, videoName[0], 'subtitle'))
            .catch(err => onFileLoaded(null, videoName[0], 'subtitle', err));
          axios.get(`${process.env.PUBLIC_URL}/videos/${videoName[1]}.vtt`, { responseType: 'text', onDownloadProgress })
            .then(res => onFileLoaded(res, videoName[1], 'subtitle'))
            .catch(err => onFileLoaded(null, videoName[1], 'subtitle', err));
        } else {
          axios.get(`${process.env.PUBLIC_URL}/videos/${videoName}.vtt`, { responseType: 'text', onDownloadProgress })
            .then(res => onFileLoaded(res, videoName, 'subtitle'))
            .catch(err => onFileLoaded(null, videoName, 'subtitle', err));
        }
      }

      setTimeout(() => {
        loadingTimerDone = true;
        if (loadedFiles >= nbFiles) {
          setReady(true);
        }
      }, MIN_LOADING_TIME);
    }
  }, [ready, state.choices]);
  
  return (
    cookies.authorized ? (
      <FullScreen enabled={fullscreen} onChange={isFull => setFullscreen(isFull)(dispatch)}>
        <div className={classnames('App', { ready: ready && canPlay })} >
          { !!(ready && canPlay)
            ? <HTAContext.Provider value={{ state, dispatch }}>
                <Router>
                  <Switch>
                    <Redirect exact from='/game' to='/game/1' />
                    <Route exact path='/game/:step' component={Game} />
                    <Route component={MainMenu} />
                  </Switch>
                  <Header />
                </Router>
                <Footer />
                { !!popup && <PopupOverlay /> }
              </HTAContext.Provider>
            : <Loading progress={progress} />
          }
        </div>
      </FullScreen>
    ) : (
      <Router>
        <Switch>
          <Route exact path={`/${APP_KEY}`} render={() => setCookie('authorized', true, { path: '/' })} />
        </Switch>
      </Router>
    ));
}

export default React.memo(App);